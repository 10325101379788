import { Field, Formik, Form } from 'formik'
import React, { useCallback, useMemo, useState } from 'react'
import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

export default function ContactForm({ subject }) {

    const [currentView, setCurrentView] = useState('form')

    const onSubmit = async (e) => {
        try {
            e.preventDefault()
            const form = e.target.closest('form')
            const formData = new FormData(form)
            const res = await fetch('/', {
                method: 'POST',
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: new URLSearchParams(formData).toString(),
            })

            if (res.status > 299) {
                throw Error('Invalid response ' + res.status)
            }

            setCurrentView('submitted')

        } catch (e) {
            setCurrentView('form')
            console.error(e)
        }

    }

    const Views = useMemo(() => ({
        'form': <FormView onSubmit={onSubmit} subject={subject} />,
        'submitted': <SubmittedView />
    }), [subject])

    const CurrentView = useCallback(() => {
        return Views[currentView]
    }, [Views, currentView])

    return (
        <SwitchTransition>
            <CSSTransition key={currentView} classNames='fade' timeout={500}>
                < CurrentView />
            </CSSTransition>
        </SwitchTransition>
    )
}

function SubmittedView() {
    return (
        <div>
            <h2>Thanks for you submission</h2>
        </div>
    )
}

function FormView({ onSubmit, subject }) {

    const isErrored = useCallback((name, errors, touched) => {
        return (errors[name] && touched[name])
    }, [])

    return (
        <Formik initialValues={{ subject }} >
            {({ errors, touched }) => (
                <Form onSubmit={onSubmit} name="contact" method="POST" data-netlify="true" className='contact--form grid grid-col-2 gap-y-40 gap-x-20 ' >
                    <input type="hidden" name="form-name" value="contact" />
                    <Field
                        required
                        className={'input ' + (isErrored('fname', errors, touched) ? 'error' : '')}
                        placeholder='First Name'
                        name='fname'
                        type='text'
                        validate={checkEmpty()}
                    />
                    <Field
                        required
                        className={'input ' + (isErrored('lname', errors, touched) ? 'error' : '')}
                        placeholder='Last Name'
                        name='lname'
                        type='text'
                        validate={checkEmpty()}
                    />
                    <Field
                        required
                        className={'input ' + (isErrored('email', errors, touched) ? 'error' : '')}
                        placeholder='Email'
                        name='email'
                        type='email'
                        validate={checkEmpty(validateEmail)}
                    />
                    <Field
                        required
                        className={'input ' + (isErrored('phone', errors, touched) ? 'error' : '')}
                        placeholder='Phone'
                        name='phone'
                        type='phone'
                        validate={checkEmpty(validatePhone)}

                    />
                    <Field
                        className='input col-span-2'
                        placeholder='Services required'
                        name='subject'
                        type='text'
                    />
                    <div className='col-span-2'>
                        <p className='op-50'>Message</p>
                        <Field
                            className='input col-span-2 textarea '
                            placeholder=''
                            name='message'
                            as={'textarea'}
                            type='text'
                        />
                    </div>
                    <div className='col-span-2 flex'>
                        <button className='button bg-trans text-light hover-state submit-button'>
                            Submit
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

function validateEmail(value) {
    if (isEmail(value)) {
        return
    }
    return 'Not valid email'
}

function validatePhone(value) {
    if (isMobilePhone(value)) {
        return
    }
    return 'Not a valid phone number.'
}


function checkEmpty(validator = () => '') {
    return (value) => {

        if (!value) {
            return 'Required'
        }

        return validator(value) ? validator(value) : ''
    }
}