import React from 'react'
import Seo from "../components/seo"
import Spacer from "../components/spacer"
import Layout from '../components/layout'
import ContactInformation from '../components/contactInformation'
import Form from '../components/form'
import BookQuote from "../components/bookQuote"
import { graphql } from "gatsby"

export default function Contact({ location, data }) {
    return (
        <Layout controlsEnabled={false} className='bg-black mh-100vh' page='contact' header='sticky light-mode' footer='light-mode'>
            <Seo title="Contact" />
            <Spacer />
            <div style={{ minHeight: 'calc(100vh - 372px)' }} className='p40 m-pt0 text-light flex m-wrap m-flex-reverse max-w-1600 mra mla' >
                <div className='w-50 m-100 pr60 fade--in' data-sal data-sal-delay="300">
                    
                    <ContactInformation contact={data.contact.edges[0].node} />
                </div>
                <div className='w-50 m-100 fade--in m-op-1' data-sal data-sal-delay="600">
                    <div className='m-show'>
                        <h4 className='h3 m0'>Contact Us</h4>
                        <p className='x-large max-550 op-50 m0 mb40 mt20 m-mt0 '>Based on the Gold Coast and services the Northern Rivers and Brisbane areas.</p>
                    </div>
                    <p className='bold large m0 mb20'>Schedule a complimentary on-site or video quote.</p>
                    <Form subject={(location?.state?.subject ?? '')} />
                    <div className='book--now m-show mt40 mb40'>
                        <BookQuote enabled={false} className='pos-rel m-mb20 unset m-ma' />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query ContactPage {
    contact:allDatoCmsContactInformation {
      edges {
      node {
        email
        facebook
        hipages
        instagram
        phoneNumber
        licenses {
          license
        }
      }
    }
  }
  }
`