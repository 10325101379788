import React from 'react'
import BookQuote from "./bookQuote"

export default function ContactInfo({contact}) {
    return (
        <div className='contact-information m-pt40 m-bwt1'>
            <div className='m-hide'>
                <h4 className='h3 m0 '>Contact Us</h4>
                <p className='x-large max-550 op-50 mt0 '>Based on the Gold Coast and services the Northern Rivers and Brisbane areas.</p>
            </div>
            <div className='flex m-wrap'>
                <div className='w-50 m-100 m-pr20'>
                    <div className='mb40'>
                        <p className='strong m0 mb5 m-mb10'>Email</p>  
                        <a className='link ul-link op-50 m0' href={'mailto:'+contact.email}>{contact.email}</a>
                    </div>
                    <div className='mb40'>
                        <p className='strong m0 mb5 m-mb10'>License</p>  
                        <div className='link op-50 m0'>
                            {contact.licenses.map(({license}) => {
                                return (
                                  <div>
                                  {license}
                                  </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='mb40 m-hide'>
                        <p className='strong m0 mb5 m-mb10'>Follow Us</p>  
                        <a className='link ul-link op-50 m0' href={contact.instagram}>Instagram</a><br/>
                        <a className='link ul-link op-50 m0' href={contact.facebook}>Facebook</a><br/>
                        <a className='link ul-link op-50 m0' href={contact.hipages}>HiPages</a>
                    </div>
                </div>
                <div className='w-50 m-100'>
                    <div className='mb40'>
                        <p className='strong m0 mb5 m-mb10'>Text or Call</p>  
                        <a className='link op-50 ul-link m0' href={"tel:"+contact.phoneNumber}>{contact.phoneNumber}</a>
                    </div>
                    <div className='mb40 m-show'>
                        <p className='strong m0 mb5 m-mb10'>Follow Us</p>  
                        <a className='link ul-link op-50 m0' href={contact.instagram}>Instagram</a><br/>
                        <a className='link ul-link op-50 m0' href={contact.facebook}>Facebook</a><br/>
                        <a className='link ul-link op-50 m0' href={contact.hipages}>HiPages</a>
                    </div>
                    <div className='book--now m-hide'>
                        <BookQuote enabled={false} className='pos-rel m-mb20 unset m-ma' />
                    </div>
                </div>
            </div>
        </div>
    )
}

